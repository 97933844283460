import React from "react"

import "./TeamCard.scss"

export const TeamCard: React.FunctionComponent<{
  name: string
  jobTitle: string
  info: string
  backgroundImage: string
  size: "small" | "big"
  slug: string
}> = ({ name, jobTitle, info, size, backgroundImage, slug }) => {
  size = size ? size : "big"

  return (
    <div
      className={"c-team-card --" + size}
      itemScope
      itemType="https://schema.org/employee"
      onClick={() => {
        window.location.href = "/team/" + slug
      }}
    >
      <div
        className="c-team-card--background"
        style={{
          backgroundImage: 'url("' + backgroundImage + '")',
        }}
      />
      <div className="c-team-card--content">
        <div className="--name" itemProp="name">
          {name}
        </div>
        <div
          className="--title"
          dangerouslySetInnerHTML={{ __html: jobTitle }}
        />
        <div className="--info" dangerouslySetInnerHTML={{ __html: info }} />
      </div>
    </div>
  )
}
