import React from "react"
import Layout from "../components/layout"
import { Hero } from "../components/Hero/Hero"
import { graphql } from "gatsby"
import { TeamCard } from "../components/Cards/TeamCard/TeamCard"
import { AwardCard } from "../components/Cards/AwardCard/AwardCard"
import Slider from "react-slick"
import SEO from "../components/Seo.js"
import Helmet from "react-helmet"

import "../about-us.scss"

let reviews = []

class About extends React.Component {
  constructor() {
    super()

    this.state = {
      reviewPosition: 0,

      reviewImage: "",
      reviewName: "",
      reviewContent: "",
      reviewRole: "",
    }
  }

  changeReview(direction) {
    let reviewBlock = this.refs["reviewBlock"]
    reviewBlock.classList.add("--changing")

    let toReview = this.state.reviewPosition

    if (reviews.length <= this.state.reviewPosition + direction) {
      toReview = 0
    } else if (this.state.reviewPosition + direction == -1) {
      toReview = reviews.length - 1
    } else {
      toReview += direction
    }

    let currentReview = reviews[toReview]

    setTimeout(() => {
      this.setState({
        reviewPosition: toReview,
        reviewImage: currentReview.image,
        reviewName: currentReview.name,
        reviewContent: currentReview.content,
        reviewRole: currentReview.role,
      })

      reviewBlock.classList.remove("--changing")
    }, 500)
  }

  componentDidMount() {
    this.props.data.wordpressPage.acf.eight_block.testimonial.map(item => {
      reviews.push({
        image: item.image.source_url,
        name: item.name,
        content: item.content,
        role: item.extra_info,
      })
    })

    this.changeReview(0)
  }

  render() {
    const d = this.props.data.wordpressPage
    const a = this.props.data.awards
    const t = this.props.data.team

    const sliderSettings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    }

    return (
      <Layout>
        <Helmet
          title={d.yoast.title}
          meta={[
            {
              name: "description",
              content: d.yoast.metadesc,
            },
          ]}
          link={[{ rel: "canonical", content: d.yoast.canonical }]}
        />
        <SEO
          title={d.yoast.title || d.acf.title_seo || d.title}
          description={d.yoast.metadesc || d.acf.paragraph_seo || d.excerpt}
          pathname={d.slug}
          article
        />
        <div className="sticky">
          <Hero
            title={d.acf.hero.text}
            backgroundImage={d.acf.hero.image.source_url}
          />
          <section id="about-us" className="wrapper">
            <div className="content-wrapper">
              <div className="text-content-wrapper --centered">
                <div className="text-content-title">
                  <h3>About us</h3>
                  <h2>{d.acf.first_block.title}</h2>
                </div>
                <div
                  className="text-content"
                  dangerouslySetInnerHTML={{
                    __html: d.acf.first_block.content,
                  }}
                />
              </div>
            </div>
          </section>
          <section id="our-mission" className="wrapper">
            <div className="content-wrapper">
              <div className="left-title-block">
                <div className="title-block">
                  <h2>{d.acf.second_block.title}</h2>
                </div>
                <div className="content-block">
                  <div className="text-content-wrapper">
                    <div
                      className="text-content"
                      dangerouslySetInnerHTML={{
                        __html: d.acf.second_block.list,
                      }}
                    />
                    <div
                      className="text-content"
                      dangerouslySetInnerHTML={{
                        __html: d.acf.second_block.content,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="team-ovolo" className="wrapper">
            <div
              className="squished-background"
              style={{
                backgroundImage: `url('${d.acf.hero.image.source_url}')`,
              }}
            />
            <div className="content-wrapper">
              <div className="left-title-block">
                <div className="title-block">
                  <h2>{d.acf.third_block.title}</h2>
                  <p>{d.acf.third_block.subtitle}</p>
                </div>
                <div className="content-block">
                  <div className="--hide-mobile">
                    <TeamCard
                      name={t.edges[0].node.title}
                      jobTitle={t.edges[0].node.acf.subtitle_seo}
                      info={t.edges[0].node.acf.subtitle_seo}
                      slug={t.edges[0].node.slug}
                      backgroundImage={
                        t.edges[0].node.featured_media.localFile.childImageSharp
                          .sizes.src
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="team-members">
                {t.edges.slice(1).map(({ node }, i) => {
                  return (
                    <TeamCard
                      name={node.title}
                      jobTitle={node.acf.subtitle_seo}
                      info={node.excerpt}
                      slug={node.slug}
                      size="small"
                      backgroundImage={
                        node.featured_media.localFile.childImageSharp.sizes.src
                      }
                    />
                  )
                })}
              </div>
              <div className="team-slider --hide-desktop">
                <Slider {...sliderSettings}>
                  {t.edges.map(({ node }, i) => {
                    return (
                      <TeamCard
                        name={node.title}
                        jobTitle={node.acf.subtitle_seo}
                        info={node.excerpt}
                        slug={node.slug}
                        size="small"
                        backgroundImage={
                          node.featured_media.localFile.childImageSharp.sizes
                            .src
                        }
                      />
                    )
                  })}
                </Slider>
              </div>
            </div>
          </section>
          <section id="awards" className="wrapper">
            <div className="squished-background" />
            <div className="content-wrapper">
              <div className="right-title-block">
                <a
                  className="btn --grey medium --hide-desktop"
                  href={d.acf.fourth_block.button_link}
                >
                  {d.acf.fourth_block.button_cta}&nbsp;→
                </a>
                <div className="content-block">
                  <div className="c-awards-container">
                    {a.edges.map(({ node }, i) => {
                      return (
                        <AwardCard
                          name={node.title}
                          info={node.excerpt}
                          linkUrl={"/awards/" + node.slug}
                          imageUrl={
                            node.featured_media.localFile.childImageSharp.fluid
                              .src
                          }
                        />
                      )
                    })}
                    <div className="c-awards-bump" />
                  </div>
                </div>
                <div className="title-block">
                  <h2>{d.acf.fourth_block.title}</h2>
                  <a
                    className="btn --grey medium --hide-mobile"
                    href={d.acf.fourth_block.button_link}
                  >
                    {d.acf.fourth_block.button_cta}&nbsp;→
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section id="careers" className="wrapper">
            <div className="content-wrapper">
              <div className="left-title-block">
                <div className="title-block">
                  <h2>{d.acf.sixth_block.title}</h2>
                  <p>{d.acf.sixth_block.subtitle}</p>
                </div>
                <div className="content-block">
                  <a
                    href={d.acf.sixth_block.button_link}
                    className="btn --black medium"
                  >
                    {d.acf.sixth_block.button_cta}&nbsp;→
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section id="internship" className="wrapper --dark-bg">
            <div className="content-wrapper">
              <h2>{d.acf.seventh_block.title}</h2>
              <div className="left-title-block --widest">
                <div className="title-block">
                  <h4>{d.acf.seventh_block.subtitle}</h4>
                  <p>{d.acf.seventh_block.subsubtitle}</p>
                </div>
              </div>
              <div className="two-columns">
                <div className="column">
                  <div className="text-content-wrapper">
                    <div
                      className="text-content"
                      dangerouslySetInnerHTML={{
                        __html: d.acf.seventh_block.first_content_block,
                      }}
                    />
                  </div>
                </div>
                <div className="column">
                  <div className="text-content-wrapper">
                    <div
                      className="text-content"
                      dangerouslySetInnerHTML={{
                        __html: d.acf.seventh_block.second_content_block,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="centre-button-wrapper">
                {d.acf.seventh_block.first_button_cta !== "" && (
                  <a
                    href={d.acf.seventh_block.first_button_link}
                    className="btn --sandy medium"
                  >
                    {d.acf.seventh_block.first_button_cta}&nbsp;→
                  </a>
                )}

                {d.acf.seventh_block.second_button_cta !== "" && (
                  <a
                    href={d.acf.seventh_block.second_button_link}
                    className="btn --sandy medium"
                  >
                    {d.acf.seventh_block.second_button_cta}&nbsp;→
                  </a>
                )}

                {d.acf.seventh_block.third_button_cta !== "" && (
                  <a
                    href={d.acf.seventh_block.third_button_link}
                    className="btn --sandy medium"
                  >
                    {d.acf.seventh_block.third_button_cta}&nbsp;→
                  </a>
                )}
              </div>
            </div>
          </section>
          <section id="review" className="wrapper" ref="reviewBlock">
            <div className="content-wrapper">
              <div className="quote-wrapper">
                <div className="quote-wrapper--bg" />
                <div className="quote-wrapper--inner">
                  <div className="quote-wrapper--avatar --hide-mobile">
                    <img src={this.state.reviewImage} />
                  </div>
                  <div className="quote-wrapper--content">
                    <div className="text-content-wrapper">
                      <div className="text-content">
                        <p
                          id="review-content"
                          dangerouslySetInnerHTML={{
                            __html: this.state.reviewContent,
                          }}
                        />
                      </div>
                    </div>
                    <div className="quote-wrapper--signature" ref="signature">
                      <h4>{this.state.reviewName}</h4>
                      <h5>{this.state.reviewRole}</h5>
                    </div>
                    <div className="quote-wrapper--content-sliders">
                      <button
                        className="slider-left"
                        onClick={() => this.changeReview(-1)}
                        type="button"
                      />
                      <button
                        className="slider-right"
                        onClick={() => this.changeReview(1)}
                        type="button"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="management-trainee" className="wrapper">
            <div className="content-wrapper">
              <h2>{d.acf.ninth_block.title}</h2>
              <div className="left-title-block --widest">
                <div className="title-block">
                  <h4>{d.acf.ninth_block.subtitle}</h4>
                  <p>{d.acf.ninth_block.subsubtitle}</p>
                </div>
              </div>
              <div className="two-columns">
                <div className="column">
                  <div className="text-content-wrapper">
                    <div
                      className="text-content"
                      dangerouslySetInnerHTML={{
                        __html: d.acf.ninth_block.first_content_block,
                      }}
                    />
                  </div>
                </div>
                <div className="column">
                  <div className="text-content-wrapper">
                    <div
                      className="text-content"
                      dangerouslySetInnerHTML={{
                        __html: d.acf.ninth_block.second_content_block,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="centre-button-wrapper">
                {d.acf.ninth_block.first_button_cta !== "" && (
                  <a
                    href={d.acf.ninth_block.first_button_link}
                    className="btn --black medium"
                  >
                    {d.acf.ninth_block.first_button_cta}&nbsp;→
                  </a>
                )}

                {d.acf.ninth_block.second_button_cta !== "" && (
                  <a
                    href={d.acf.ninth_block.second_button_link}
                    className="btn --black medium"
                  >
                    {d.acf.ninth_block.second_button_cta}&nbsp;→
                  </a>
                )}
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default About

export const pageQuery = graphql`
  query {
    wordpressPage(slug: { eq: "about" }) {
      title
      slug
      yoast {
        title
        metadesc
        canonical
      }
      acf {
        title_seo
        subtitle_seo
        paragraph_seo
        first_block {
          title
          content
        }
        second_block {
          title
          list
          content
        }
        third_block {
          title
          subtitle
        }
        fourth_block {
          title
          button_cta
          button_link
        }
        sixth_block {
          title
          subtitle
          button_cta
          button_link
        }
        seventh_block {
          title
          subtitle
          subsubtitle
          first_content_block
          second_content_block
          first_button_cta
          first_button_link
          second_button_cta
          second_button_link
          third_button_cta
          third_button_link
        }
        eight_block {
          testimonial {
            image {
              source_url
            }
            content
            name
            extra_info
          }
        }
        ninth_block {
          title
          subtitle
          subsubtitle
          first_content_block
          second_content_block
          first_button_cta
          first_button_link
          second_button_cta
          second_button_link
        }
        hero {
          image {
            source_url
          }
          text
        }
      }
    }
    awards: allWordpressWpAwards(
      sort: { fields: [date], order: DESC }
      limit: 6
    ) {
      edges {
        node {
          title
          excerpt
          slug
          link
          featured_media {
            localFile {
              childImageSharp {
                id
                fluid(maxHeight: 275, cropFocus: NORTH) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    team: allWordpressWpTeam(sort: { fields: [date], order: ASC }, limit: 5) {
      edges {
        node {
          title
          slug
          link
          excerpt
          acf {
            subtitle_seo
          }
          featured_media {
            localFile {
              childImageSharp {
                sizes(maxWidth: 850) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    }
  }
`
