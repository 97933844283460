import React from "react"

import "./AwardCard.scss"

export const AwardCard: React.FunctionComponent<{
  name: string
  info: string
  linkUrl: string
  imageUrl: string
}> = ({ name, info, linkUrl, imageUrl }) => {
  return (
    <div
      className={"c-award-card"}
      itemScope
      itemType="https://schema.org/employee"
    >
      <div className="c-award-card--content">
        <div className="--logo" itemProp="name">
          <img src={imageUrl} />
        </div>
        <div className="c-award-card--description">
          <div className="--title">
            <div
              className="--head"
              dangerouslySetInnerHTML={{ __html: name }}
            />
          </div>
          <div className="--info">
            <a href={linkUrl}>Read More</a>
          </div>
        </div>
      </div>
    </div>
  )
}
