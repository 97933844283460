import React from "react"

import "./Hero.scss"

export const Hero: React.FunctionComponent<{
  title: string
  backgroundImage: string
}> = ({ children, title, backgroundImage }) => (
  <section
    className={"c-hero"}
    style={{
      backgroundImage: `url('${backgroundImage}')`,
    }}
  >
    <div className="content-wrapper">
      <h1>{title}</h1>
    </div>
  </section>
)
