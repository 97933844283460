import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Twitter from "./Twitter"
import Facebook from "./Facebook"

const html_regex = /(<([^>]+)>)/gi

const SEO = ({ title, description, image, pathname, article }) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        buildTime,
        siteMetadata: {
          defaultTitle,
          titleTemplate,
          defaultDescription,
          siteUrl,
          headline,
          defaultImage,
          twitterUsername,
          siteLanguage,
          ogLanguage,
          facebook,
          twitter,
        },
      },
    }) => {
      const yoastReplace = text => {
        return text.replace("%%sep%%", "").replace("%%sitename%%", "")
      }
      const seo = {
        title: yoastReplace(title || defaultTitle),
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname || "/"}`,
      }

      // schema.org in JSONLD format
      // https://developers.google.com/search/docs/guides/intro-structured-data
      // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

      const schemaOrgWebPage = {
        "@context": "http://schema.org",
        "@type": "Organization",
        url: siteUrl,
        headline,
        inLanguage: siteLanguage,
        mainEntityOfPage: siteUrl,
        description: defaultDescription,
        name: defaultTitle,
        copyrightYear: "2019",
        address: {
          "@type": "PostalAddress",
          addressCountry: "AU",
          addressLocality: "Sydney",
          addressRegion: "NSW",
          postalCode: "2011",
          streetAddress: "6 Cowper Wharf Roadway, Woolloomooloo",
        },
        founder: {
          "@type": "Person",
          givenName: "Girish",
          familyName: "J.",
        },
        datePublished: "2019-05-09T10:30:00+01:00",
        dateModified: buildTime,
        logo: {
          "@type": "ImageObject",
          url: `${siteUrl}${image || defaultImage}`,
        },
      }

      // Initial breadcrumb list

      const itemListElement = [
        {
          "@type": "ListItem",
          item: {
            "@id": siteUrl,
            name: "Homepage",
          },
          position: 1,
        },
      ]

      let schemaArticle = null

      if (article) {
        schemaArticle = {
          "@context": "http://schema.org",
          "@type": "Article",
          author: {
            "@type": "Person",
            name: "Ovolo",
          },
          copyrightHolder: {
            "@type": "Person",
            name: "Ovolo",
          },
          copyrightYear: "2019",
          creator: {
            "@type": "Person",
            name: "Ovolo",
          },
          publisher: {
            "@type": "Organization",
            name: "Ovolo",
            logo: {
              "@type": "ImageObject",
              url: `${siteUrl}${image || defaultImage}`,
            },
          },
          description: seo.description,
          headline: seo.title,
          inLanguage: "en",
          url: seo.url,
          name: seo.title,
          image: {
            "@type": "ImageObject",
            url: seo.image,
          },
          mainEntityOfPage: seo.url,
        }
        // Push current blogpost into breadcrumb list
        itemListElement.push({
          "@type": "ListItem",
          item: {
            "@id": seo.url,
            name: seo.title,
          },
          position: 2,
        })
      }

      const breadcrumb = {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        description: "Breadcrumbs list",
        name: "Breadcrumbs",
        itemListElement,
      }

      return (
        <>
          <Helmet
            title={seo.title.replace(html_regex, "")}
            titleTemplate={titleTemplate}
            encodeSpecialCharacters={true}
          >
            <meta
              name="description"
              content={seo.description.replace(html_regex, "")}
            />
            <meta name="image" content={seo.image} />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {(article ? true : null) && (
              <meta property="og:type" content="article" />
            )}
            {seo.title && (
              <meta
                property="og:title"
                content={seo.title.replace(html_regex, "")}
              />
            )}
            {seo.description && (
              <meta
                property="og:description"
                content={seo.description.replace(html_regex, "")}
              />
            )}
            {seo.image && <meta property="og:image" content={seo.image} />}
            <meta name="twitter:card" content="summary_large_image" />
            {twitterUsername && (
              <meta name="twitter:creator" content={twitterUsername} />
            )}
            {seo.title && (
              <meta
                name="twitter:title"
                content={seo.title.replace(html_regex, "")}
              />
            )}
            {seo.description && (
              <meta
                name="twitter:description"
                content={seo.description.replace(html_regex, "")}
              />
            )}
            {seo.image && <meta name="twitter:image" content={seo.image} />}
            {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
            {!article && (
              <script type="application/ld+json">
                {JSON.stringify(schemaOrgWebPage)}
              </script>
            )}
            {article && (
              <script type="application/ld+json">
                {JSON.stringify(schemaArticle)}
              </script>
            )}
            <script type="application/ld+json">
              {JSON.stringify(breadcrumb)}
            </script>
          </Helmet>
          <Facebook
            desc={seo.description.replace(html_regex, "")}
            image={seo.image}
            title={seo.title}
            type={article ? "article" : "website"}
            url={seo.url}
            locale={ogLanguage}
            name={facebook}
          />
          <Twitter
            title={seo.title.replace(html_regex, "")}
            image={seo.image}
            desc={seo.description.replace(html_regex, "")}
            username={twitterUsername}
          />
        </>
      )
    }}
  />
)

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
}

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
        headline
        siteLanguage
        ogLanguage
        facebook
      }
    }
  }
`
